var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{class:_vm.siteMode === 'UH' ? 'login-app-uh-marketing' : 'login-app'},[_c('v-container',{staticClass:"login-container"},[_c('v-row',{staticClass:"mb-8"},[_c('v-col',{staticClass:"d-flex justify-left align-left",attrs:{"cols":"12","md":"6"}},[_c('div',{class:_vm.$mq === 'mobile'
              ? 'd-flex justify-center align-end'
              : 'd-flex justify-left align-end'},[_c('v-img',{staticClass:"mt-3",attrs:{"src":_vm.siteMode === 'UH' ? _vm.logoUH : _vm.logoVP,"contain":"","width":_vm.siteMode === 'UH' ? 150 : 150}}),_c('h1',{class:_vm.siteMode === 'UH'
                ? 'display-2 white--text font-weight-bold'
                : 'display-2 white--text font-weight-bold'},[_vm._v(" "+_vm._s(_vm.siteMode === 'UH' ? 'VPExam' : 'VPExam')+" ")])],1)])],1),_c('v-card',{staticClass:"login-card"},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{class:_vm.$mq === 'mobile' ? '' : 'fill-height',attrs:{"cols":"12","md":"6"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"text-md-h4"},[_vm._v("Login To Your Account")]),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"id":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.signIn.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{attrs:{"name":"email","label":"Email","type":"text","rules":[_vm.rules.required],"outlined":"","rounded":"","dense":""},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('v-text-field',{attrs:{"id":"password","type":_vm.hidePassword ? 'password' : 'text',"append-icon":_vm.hidePassword ? 'visibility_off' : 'visibility',"name":"password","label":"Password","rules":[_vm.rules.required],"outlined":"","rounded":"","dense":""},on:{"click:append":function($event){_vm.hidePassword = !_vm.hidePassword}},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})],1),_c('div',{style:(_vm.$mq === 'mobile'
                    ? ''
                    : 'display: flex;justify-content: space-between;align-items: flex-end;')},[_c('v-btn',{staticStyle:{"float":"right"},attrs:{"block":_vm.$mq === 'mobile',"color":"primary","loading":_vm.loading,"disabled":!_vm.valid},on:{"click":_vm.signIn}},[_vm._v("Sign In")]),_c('div',{staticStyle:{"margin-top":"12px"}},[_c('ForgotPasswordDialog')],1)],1),_c('div',{staticClass:"mt-4"},[_c('v-btn',{staticStyle:{"padding":"0 5px"},attrs:{"to":"/patientregistration","color":"primary","text":""}},[_c('span',{staticClass:"mr-1",staticStyle:{"color":"#9E9E9E !important"}},[_vm._v("Don't have an account?")]),_vm._v(" Register Here! ")])],1)],1)],1)],1),_c('v-col',{staticClass:"fill-height",attrs:{"cols":"12","md":"6"}},[_c('v-card',{class:_vm.$mq === 'mobile'
                ? 'd-flex flex-column justify-space-between'
                : 'd-flex flex-column justify-space-between fill-height',attrs:{"flat":""}},[_c('v-card-title',{staticClass:"text-md-h4"},[_vm._v("Login as Guest User")]),_c('v-card-text',{staticClass:"black--text text-md-h6"},[_vm._v("VPExam gives your doctor all the critical information needed to provide the highest quality of care.")]),_c('v-card-text',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.guestDialog = true}}},[_vm._v("Get Started")])],1)],1)],1)],1),_c('v-snackbar',{attrs:{"absolute":""},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.error)+" "),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":function($event){_vm.snackbar = false}}},[_vm._v("Okay")])],1)],1),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.guestDialog),callback:function ($$v) {_vm.guestDialog=$$v},expression:"guestDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Guest Registration "),_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":function($event){_vm.guestDialog = false}}},[_vm._v("Cancel")])],1),_c('v-card-text',[_c('GuestRegister')],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }